<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Professional Academic Communication, Conduct & Integrity Agreement</h2>

      <p class="mb-3">
        <b>Professional Academic Communication and Lab Conduct</b>:
        <i
          >all students are required to complete the academic integrity and communication agreement
          before entering the lab for the first time</i
        >. You are responsible for communicating respectfully and using professional and/or academic
        language with the instructional staff and your peers, and for following the directions of
        your TA while you are in the lab.
      </p>

      <ul class="mb-5">
        <li>
          TAs are present to direct the lab activities so each of you can complete your tasks in a
          timely manner. You must follow their instructions so that everyone in the lab, including
          yourself, is physically safe and has an opportunity to learn.
        </li>
        <li>
          Teamwork is essential in the laboratory. You must have an open mind, actively listen, and
          share responsibilities and ideas with your peers. You must handle disagreements
          respectfully and productively, in consultation with your TA if necessary.
          <a
            href="https://docs.google.com/document/d/e/2PACX-1vRlVElm71_fMJFVQDY3u8rkLwqAhaCNJ9SPC3B6_tP7rns6OBQyzhQP61HBjTh5KwP589S4mJS-XspN/pub"
            rel="noopener noreferrer"
            target="_blank"
            >Peer interaction guidelines
          </a>
          are provided to help with this process.
        </li>
        <li>
          Professional speech and behavior are expected while you are in lab. Excessive foul
          language and aggressive or disruptive behavior have no place in the lab and will not be
          tolerated. You will be asked to leave if your behavior is making others uncomfortable.
          Repeated infractions will result in your removal from the course.
        </li>
        <li>
          If someone else's actions are making you uncomfortable, please let your TA, the course
          instructor, or
          <a
            href="https://aisc.uci.edu/resources/reporting.php"
            rel="noopener noreferrer"
            target="_blank"
            >student conduct
          </a>
          know.
        </li>
        <li>
          Because general chemistry laboratory courses have a high enrollment, certain expectations
          and rules must be adhered to for the smooth functioning of the course as a whole and to
          maintain the best degree of fairness possible. Keep in mind that the idea of fairness is
          not one that can be met with a high degree of perfection, but we endeavor to do our very
          best in this area.
        </li>
        <li>
          Absences of any type and for any reason will all be handled the same way. Tokens must be
          exchanged for make up data or a make up session, regardless of the reason for the need.
          Attendance at lab is an absolute necessity for you to learn the technique and process of
          scientific experimentation. Therefore, more than one absence cannot be tolerated and the
          make up of that absence must have value that encourages the student to only opt for such a
          situation when absolutely necessary.
        </li>
        <li>
          In-person and email communication between the instructional staff (your TA, head TAs and
          the course instructor) and students must be polite and respectful. If you would like to
          understand the grading feedback on an assignment, you may ask the TA for an explanation.
          However, once a TA has validated the accuracy of the grading, you must accept that
          decision. Furthermore, grading issues must always be handled in person with your TA first,
          then the course instructor. Email requests regarding grading issues will not be answered,
          such issues must be handled in a timely way during office hours.
        </li>
        <li>
          Teaching assistants are also graduate students with their own separate work. In person
          contact with them beyond their lab section and office hour time is not permitted.
        </li>
        <li>
          Harassment or bullying of instructors and students, including via electronic media, the
          internet, social networks, blogs, cell phones, and text messages, will not be tolerated.
          Students found responsible for such infractions are subject to disciplinary sanctions.
        </li>
      </ul>

      <p class="mb-3">
        Students who fail to comply could be in violation of the
        <a
          href="https://aisc.uci.edu/policies/pacaos/grounds-for-discipline.php"
          rel="noopener noreferrer"
          target="_blank"
          >student code of conduct
        </a>
        and may be referred to OAISC. These are the policies you may be in violation of:
      </p>

      <ul class="mb-5">
        <li>
          102.13 Obstruction or disruption of teaching, research, administration, disciplinary
          procedures, or other University activities
        </li>
        <li>
          102.16 <b>Failure</b> to identify oneself to, <b>or comply with the directions of</b>, a
          University official or other public official acting in the performance of his or her
          duties while on University property or at official University functions; or resisting or
          obstructing such University or other public officials in the performance of or the attempt
          to perform their duties.
        </li>
        <li>102.14 Disorderly or lewd conduct.</li>
      </ul>

      <p class="mb-3">
        If you are found in violation of these policies, you can face any of the following
        sanctions: Warning, Disciplinary probation, Suspension and Dismissal. For more information
        on the student conduct procedures, check out their
        <a
          href="https://aisc.uci.edu/students/student-conduct/conduct-process.php"
          rel="noopener noreferrer"
          target="_blank"
          >website </a
        >.
      </p>

      <p v-for="option in optionsQ0" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.question0Values" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'UCIrvineIntegrityAgreementQ1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question0Values: [],
      },
      optionsQ0: [
        {
          text: 'I have read the above information and understand that I must complete the remaining tasks in this assignment.',
          value: 'readInformationAnswer',
        },
      ],
    };
  },
};
</script>
